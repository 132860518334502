<template>
  <div>
    <trac-tab-window :sectionTitle="'Settings'" :subLinks="subLinks" />
  </div>
</template>

<script>
import MixinForScrollToTop from '../../offline-module/mixins/TabbedMixin'
export default {
  mixins: [MixinForScrollToTop],
  data() {
    return {
      subLinks: [
        {
          name: "Business Profile",
          path: "/settings/kycProfile",
          selected: false,
          routerWindowClassString: 'bg-white mt-12 p-12 big-shadow',
          dataAttribute: "business-profile-button",
        },
        {
          name: "Stores",
          path: "/settings/all-stores",
          selected: false,
          routerWindowClassString: 'bg-white mt-12 p-12 big-shadow -mr-1',
          dataAttribute: "stores-button",
        },
        {
          name: 'Sales Agent',
          path: '/settings/sales-agent',
          selected: false,
          routerWindowClassString: 'mt-6',
          dataAttribute: 'sales-agent-button',
        },
        {
          name: "Users",
          path: "/settings/user-roles",
          selected: false,
          routerWindowClassString: 'bg-white mt-12 p-12 big-shadow',
          dataAttribute: "users-button",
        },
        {
          name: "Payment Settings",
          path: "/settings/payment-methods",
          selected: false,
          routerWindowClassString: 'bg-white mt-12 p-12 big-shadow',
          dataAttribute: "payment-settings",
        },
        // {
        //   name: "Integrations",
        //   path: "/settings/integrations",
        //   selected: false,
        //   routerWindowClassString: 'bg-white mt-12 p-12 big-shadow',
        //   dataAttribute: "integrations-button",
        // },
        {
          name: "Sync Settings",
          path: "/settings/syncsettings",
          selected: false,
          routerWindowClassString: 'bg-white mt-12 p-12 big-shadow',
          dataAttribute: "sync-settings-button",
        }
      ],
    };
  },
  created () {
    console.log('path: ', this.$route.path)
    const enteredPath = this.$route.path
    const subLinkIndex = this.subLinks.findIndex(link => link.path === enteredPath)
    // debugger
    if (subLinkIndex > -1) {
      this.subLinks[subLinkIndex].selected = true
    } else {
      // do nothing
    }
  },
};
</script>

<style lang="scss" scoped>
</style>